(() => {
    var count = 0;
    var activeInputs = [];
  
    if( document.querySelector("#background")) {
      //Background logos
      const width = document.getElementById('content').clientWidth;
      const height = document.getElementById('content').clientHeight;
      let backgroundElement = document.querySelector("#background");
  
      for (let i = 0; i < backgroundElement.dataset.number ; i++) {
        backgroundElement.innerHTML +=
          "<img id='logo-" + i + "' src='" + backgroundElement.dataset.icon + "' class='position-absolute' alt='Aviada Logo' />";
        let element = document.querySelector("#logo-" + i );
        element.style.opacity = Math.random() * 0.1;
        element.style.width = Math.floor(Math.random() * 800 + 91) + "px";
        element.style.height = "auto";
        element.style.top = Math.floor(Math.random() * height) + "px";
        element.style.left = Math.floor(Math.random() * width) + "px";
      }
        //Parallax effect
        window.addEventListener('scroll', () => {
          const amount = backgroundElement.dataset.number;
          for (let i = 0; i < amount ; i++) {
            let section = document.querySelector("#logo-" + i ); 
            let half = Math.floor(amount/2);
            section.style.transform = i < half ? `translateY(-${window.scrollY*0.5}px)` : i < amount ? `translateY(-${window.scrollY*0.3}px)` : `translateY(-${window.scrollY*0.1}px)`;
          }
        });
      }
      
      if(document.querySelector("#hiring-process")) {
        //Hiring process position
        const total = document.querySelector("#hiring-process").dataset.steps;
        const type = total % 2;
        const middle = Math.round(total / 2);
        const middle2 = (type === 0) ? middle + 1 : null;
        //Hiring process animation
        const arrowMove = 330/total;
        //Arrow animation
        const arrow = () => {
          const scrolled = document.querySelectorAll(".scrolled").length;  
          const arrow = document.querySelector(".arrow");
          arrow.style.cssText = "-webkit-mask-size: " + scrolled * arrowMove + "%;";
          arrow.classList.add("move");
        };

        for(let i=1; i <= total; i++) {
          if(i <= middle) {
            offset = i;
          } else if (i === middle2) {
            offset = offset;
          } else {
            offset --;
          }  
          document.querySelector("#icon-" + i).classList.add("offset-md-" + offset);
        }
      
        //Check if scroll is at step location 
        const atStep = (st) => {
          const elementTop = st.getBoundingClientRect().top;
          return (
            elementTop <=
            (window.innerHeight || document.documentElement.clientHeight) 
            );
        };
      
        //Add the animation class
        const showStep = (element) => {
          element.classList.add("scrolled");
          arrow();
        };
        
        //Steps fade in
        const steps = () => {
          const steps = document.querySelectorAll(".step");
          steps.forEach((st) => {
            if (atStep(st)) {
              showStep(st);
            }
          });
        };
  
        //Reveal hiring process
        window.addEventListener('scroll', () => {
            steps();
        });
    }  

    // Background color change
    window.addEventListener('scroll', () => {
      if(document.querySelector("#our-team") !== null && (window.scrollY + 200) >= document.querySelector("#our-team").offsetTop ) {
        document.querySelector("body").classList.add("our-team");
      } else {
        document.querySelector("body").classList.remove("our-team");
      }
    });
  
    //Remove cookie disclaimer alert
    localStorage.getItem('cookies')
      ? null
      : document.getElementById("cookie-disclaimer").classList.remove('d-none');
    
    //Cookie to display the disclaimer once.
    document.querySelector("#cookie-disclaimer .btn-close")
      .onclick = e => {
        localStorage.setItem('cookies', true);
      };
  
    if(document.querySelector('#our-services-form .wpforms-submit')) {
      document.querySelector('#our-services-form .wpforms-submit')
        .onclick = e => {
          gtag('event', 'client_contact', {
            'page': location.pathname
          });
        };
    }
  
    if(document.querySelectorAll('#clients a.logo-link')) {
      document.querySelectorAll('#clients a.logo-link')
        .forEach(logo => {
          logo.onclick = e => {
            gtag('event', 'click_client_page', {
              method: e.currentTarget.id,
              content_type: "page"
            });
          };
        });
    }
  
    document.querySelectorAll('.social-link')
      .forEach(network => {
        network.onclick = e => {
          gtag("event", "social_network_link", {
            method: e.currentTarget.id,
            content_type: "page"
          });
        };
      });

  })();